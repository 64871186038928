import React from 'react';
import close_icon from "./icons_android/x_android.svg";
import iomoney from "./icons_android/iomoney.svg";
import wmz from "./icons_android/wmz.svg";
import { openUrl, showAndroidToast } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { ReCaptcha } from 'react-recaptcha-v3'
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import { getMoneyType, getSmoozieMultiplier } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
import { min } from 'moment';
class GetPayout extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tooltipText : "",
            payoutWallet : (this.props.wallet_new != undefined ? (this.props.wallet_new.includes("Z") || this.props.wallet_new.includes("z")) ? this.props.wallet_new : "" : ""),
            moneyEntered : Math.floor(this.props.count_money_r) * getSmoozieMultiplier(),
            done : true,
            isDisable : true,
            isShowTooltip : false,
            isUpdateCaptcha : false,
            isShowPayout : true,
            isWebmoneyPicked : true
        }
        this.countClicked = 0
        
    }
    componentDidMount(){
        this.checkServerTime()
    }
    checkServerTime = () => {
        // var params = getRequestParams()
        // fetch("https://app-coins.ru/api/getDate", {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        //     body: qs.stringify(params)
        //     }).then((response) => response.json())
        //     .then((responseData) => {
        //         var hour = new Date(responseData.time).getUTCHours();
        //         this.setState({isShowPayout : (hour >= 7 && hour < 15)})
        //         console.log("getDate hour = " + hour)
        //     })
        
    }
    componentDidUpdate(prevProps) {
        if (prevProps.count_money_r !== this.props.count_money_r) {
          this.setState({moneyEntered : parseInt(this.props.count_money_r)})
        }
        if (prevProps.wallet_new !== this.props.wallet_new) {
            this.setState({payoutWallet : parseInt(this.props.wallet_new)})
        }
    }
    verifyCallback = (recaptchaToken) => {
        this.getPayout(recaptchaToken)
    }
    inputChanged = (event) => {
        if (event){
            this.setState({moneyEntered : event.target.value})
        }
    }
    inputWalletChanged = (event) => {
        if (event){
            this.setState({payoutWallet : event.target.value})
        }
    }
    openYandexUrl = () => {
        openUrl("https://yoomoney.ru/page?id=536140")
    }
    openWebMoneyUrl = () => {
        openUrl("https://play.google.com/store/apps/details?id=com.webmoney.my")
    }
    getPayoutClicked = () => {
        const min_transfer = this.state.isWebmoneyPicked ? 20 : 200
        this.setState({isShowTooltip : false, isShowWalletTooltip : false})
        if (this.state.isWebmoneyPicked && !this.state.payoutWallet.includes("Z")){
            this.setState({isShowTooltip : true, tooltipText : "Webmoney кошелек должен начинаться с Z", isShowWalletTooltip : false})
            return
        }
        if (!this.state.isWebmoneyPicked && !this.state.payoutWallet.includes("4100")){
            this.setState({isShowTooltip : true, tooltipText : "ЮMoney кошелек должен начинаться с цифр 4100", isShowWalletTooltip : false})
            return
        }
        if (parseInt(min_transfer) > parseInt(this.state.moneyEntered)){
            this.setState({isShowTooltip : true, tooltipText : "Недостаточно денег для выплаты. Введите сумму больше минимальной.", isShowWalletTooltip : false})
            return
        }
        if (this.countClicked >= 1){
            //this.setState({isUpdateCaptcha : true})
            this.getPayout()
        } else {
            this.setState({isShowTooltip : true, tooltipText : "Внимательно проверьте номер кошелька. Отменить ошибочный платёж будет невозможно.", isShowWalletTooltip : true})
        }
        this.countClicked += 1
    }
    getPayout = (timestamp) => {
        this.setState({isUpdateCaptcha : false})
        this.setState({done : false})
            this.closeTooltip()
            var params = getRequestParams()
            var dateTime = new Date().getTime()
            params.purse = "" + this.state.payoutWallet
            params.amount = "" + Math.floor(this.state.moneyEntered / getSmoozieMultiplier())
            params.currency = "45"
            params.time = "" + dateTime
            if (timestamp){
                params.timestamp = timestamp
            }
            if (!this.state.isWebmoneyPicked){
                params.paymentType = "YouKassa"
            }
           
            fetch("https://app-coins.ru/api/getPayments", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    showAndroidToast("Выплата успешно заказана, ожидайте поступления средств")
                    //this.props.updateMoney(-this.state.moneyEntered)
                    this.props.getMyInfo()
                } else {
                    this.setState({isShowTooltip : true, tooltipText : (responseData.desc ? responseData.desc : responseData.err), isShowWalletTooltip : false})
                }
                console.log("getPayout responseData = ", responseData)
                this.setState({done : true})
            })
    }
    closeTooltip = () => {
        this.setState({isShowTooltip : false})
    }
    changePayoutType = (isWebmoneyPicked) => {
        if (isWebmoneyPicked){
            var walletNumber = this.props.wallet_new != undefined && (this.props.wallet_new.includes("Z") || this.props.wallet_new.includes("z")) ? this.props.wallet_new : ""
            this.setState({isWebmoneyPicked : true, payoutWallet : walletNumber})
        } else {
            var walletNumber = this.props.wallet_new != undefined && (this.props.wallet_new.includes("4100") || this.props.wallet_new.includes("4100")) ? this.props.wallet_new : ""
            this.setState({isWebmoneyPicked : false, payoutWallet : walletNumber})
        }

    }
    render() {
        const min_transfer = this.state.isWebmoneyPicked ? 20 * getSmoozieMultiplier() : 200 * getSmoozieMultiplier()
        const isShowPayout = Math.floor(this.props.count_money_r) * getSmoozieMultiplier() >= min_transfer
    
        if (!this.state.done){
            return(<Loader style={{top : '200px'}}/>) 
        } else return (
            
            <div className="block">
                {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>}
                <div className="payout_header_container">
                    <p className="text_payout_header">Твой баланс</p>
                    <p className="money_header_payout">{Math.floor(this.props.count_money_r) * getSmoozieMultiplier() + getMoneyType(this.props.isUpmob)}</p>
                    <p style={{fontSize : '12px', opacity : '0.5'}} className="text_payout_header">{getSmoozieMultiplier() + getMoneyType(this.props.isUpmob) + " = 1 рубль"}</p>
                   {/* <p className="text_payout_header">Минимальная сумма выплаты {this.props.min_transfer}₽</p>*/}
                </div>
                {/* {!this.state.isShowPayout ?  */}
                {/* test */}
                {!this.state.isShowPayout ? 
                <div>
                    <p className="task_name padding_16 payout_title">На данный момент, вывод денег доступен с 10:00 утра до 18:00 по московскому времени.<br/>Приносим извинения за неудобства.</p>
                </div> : 
                <div className='payout_other'>
                    {/* <p className="task_name padding_16 payout_title">Вывести деньги можно
                        только на именной кошелёк
                        Я.Денег
                        (комиссия 1.5%).</p>
                    <div className="padding_16">
                        <p onClick={this.openYandexUrl} className="task_name bot_0">Как его
                            оформить:</p>
                        <p className="link_yandex">https://yandex.ru/support/money/identification/general.html</p>
                    </div>
                    <div>
                        
                        <div className="padding_16">
                            <p onClick={this.openYandexUrl} className="task_name bot_0">Как его
                                оформить:</p>
                            <p className="link_yandex">https://yandex.ru/support/money/identification/general.html</p>
                        </div>
                    </div> */}
                    {/*<div>
                        <p className="task_name padding_16 payout_title">Вывести деньги теперь можно
                            только на WMZ (WebMoney кошелек)
                            (комиссия 3%).</p>
                        <div className="padding_16">
                            <p onClick={this.openWebMoneyUrl} className="task_name bot_0">Оформить можно через Android приложение WebMoney Keeper:</p>
                            <p onClick={this.openWebMoneyUrl} className="link_yandex">https://play.google.com/store/apps/details?id=com.webmoney.my</p>
                        </div>
                        <div>
                            
                            <div className="padding_16">
                                <p className="task_name bot_0">После регистрации Вам необходимо создать WMZ кошелек. Кошелек будет долларовый и деньги автоматически переведутся в доллары.</p>
                            </div>
                        </div>
                    </div> */}
                    <div className="padding_16">
                        <p className="task_name payout_title">Способ оплаты</p>

                        <div className="payout_picker_container">
                            <div onClick={() => this.changePayoutType(true)} style={this.state.isWebmoneyPicked ? {background : '#EBECF2'} : {}} className="payout_picker_img_cont hover">
                                <img src={wmz} className="center_hor payout_picker_img" />
                            </div>
                            
                            <div className="payout_picker_line"/>                            

                            <div onClick={() => this.changePayoutType(false)} style={!this.state.isWebmoneyPicked ? {background : '#EBECF2'} : {}} className="payout_picker_img_cont hover">
                                <img src={iomoney} className="center_hor payout_picker_img" />
                            </div>
                        </div>
                    </div>
                    <div>
                        {this.state.isWebmoneyPicked ? <div className="descr_payout">
                            <p><b>Минимальная сумма выплаты на WMZ - {min_transfer + getMoneyType(this.props.isUpmob)} ({20}₽).</b> Деньги будут автоматически переконвертированы в доллары при зачислении на ваш WMZ-кошелёк (комиссия 0.01$).</p>
                            <p onClick={this.openWebMoneyUrl} className="task_name bot_0">Оформить можно через Android приложение WebMoney Keeper:</p>
                            <p onClick={this.openWebMoneyUrl} className="link_yandex">https://play.google.com/store/apps/details?id=com.webmoney.my</p>
                        </div> : <div className="descr_payout">
                            <p><b>Минимальная сумма выплаты на ЮMoney - {min_transfer + getMoneyType(this.props.isUpmob)} ({200}₽).</b> Вывести деньги можно только на <span onClick={this.openYandexUrl} className="link_yandex">именной кошелёк ЮMoney</span> (комиссия 5%).</p>
                        </div>}
                        
                        {/* <input onChange={this.inputWalletChanged} value={this.state.payoutWallet} className="input_wallet"
                            placeholder="Номер кошелька, начинается на 4100" type="number"/> */}
                        <div>
                            <input onChange={this.inputWalletChanged} value={this.state.payoutWallet} className="input_wallet" type={this.state.isWebmoneyPicked ? "text" : "number"}
                                placeholder={this.state.isWebmoneyPicked ? "Номер кошелька, начинается с буквы Z" : "Номер кошелька, начинается на 4100"} />

                            <div className="input_container">
                                <input onChange={this.inputChanged} value={this.state.moneyEntered != undefined && this.state.moneyEntered != "" ? parseInt(this.state.moneyEntered) : ""}
                                    className="input_wallet input_bot" placeholder="Введите сумму выплаты"
                                    type="number"/>
                                    <p className="task_name rub_icon_input">{getMoneyType(this.props.isUpmob)}</p>
                            </div>
                        
                            <div className="relative">
                                <div className={this.state.isShowTooltip ? 'block' : 'not_display'}>
                                    <div onClick={this.closeTooltip}
                                        className="tooltip_plashka_container transformable-profiles tooltip_plashka">
                                        <img className="close_icon" src={close_icon} alt=""/>
                                        <div className="padding_16 tooltip_right">
                                            <p className="task_name">{this.state.tooltipText}</p>
                                            <p className={this.state.isShowWalletTooltip ? 'block' : 'not_display'}><strong>{this.state.payoutWallet}</strong></p>
                                        </div>
                                        <div className="tooltip_arrow"></div>
                                        <div className="line_white_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={!isShowPayout ? {pointerEvents : 'none', opacity : '0.2'} : {}} className={!(this.state.payoutWallet != "" && this.state.moneyEntered >= min_transfer) ? 'payout_btn_container_disabled' : 'payout_btn_container'}>
                            <div style={{margin : '24px auto'}} disabled={!(this.state.payoutWallet != "" && this.state.moneyEntered >= min_transfer)} onClick={this.getPayoutClicked} className="task_btn ref_btn_width center_btn">Заказать выплату</div>
                        </div>
                    
                    
                    </div>
                </div>}
                
                
            </div>
           
        );
    }
}
export default GetPayout;