/* eslint-disable eqeqeq */
import React from 'react';
import { getRequestParams, getStartedOrders, addStartedOrder } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import { getHttpParams } from '../custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import { AppsType, getMoneyType, getSmoozieMultiplier } from '../custom_views/insta_custom_views/utils_android/UtilsAndroid';

// Перемещаем переменную внутрь компонента, так как она теперь будет использоваться через useState
const TaskItem = (props) => {
    const [readyToPerfomIds, setReadyToPerfomIds] = React.useState([]);

    const openTask = () => {
        const params = getRequestParams();
        params.order_id = props.task._id;

        fetch("https://app-coins.ru/api/readyToPerfom", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.response === 1) {
                    setReadyToPerfomIds(prev => [...prev, {
                        id: props.task._id,
                        deleteTime: (new Date().getTime() / 1000 + 1200)
                    }]);
                    props.onTaskClicked(props.task, 'task', 'tasks');
                    if (!getStartedOrders().includes(props.task._id)) {
                        addStartedOrder(props.task._id);
                    }
                } else {
                    props.orderError();
                }
            });
    };

    const onTaskClicked = () => {
        let isFound = false;
        const currentTime = new Date().getTime() / 1000;

        setReadyToPerfomIds(prev => {
            const filtered = prev.filter(element => {
                if (element.id === props.task._id) {
                    isFound = true;
                }
                return currentTime < element.deleteTime;
            });
            return filtered;
        });

        if (isFound) {
            props.onTaskClicked(props.task, 'task', 'tasks');
        } else {
            openTask();
        }
    };

    return (
        <div className="task_item_container hover" onClick={onTaskClicked}>
            <div className="flex task_item_full_width">
                <img className="task_item_icon custom_icons_task_shadow" src={"https://" + props.task.icon} alt="" />
                <div className="block justify_content task_item_full_width">
                    <p className="task_item_title task_name_overflow">{props.task.name}</p>
                    <p className="task_item_type">{props.task.type_os == AppsType.android ? "Задание в Google Play"  : "Задание в RuStore"}</p>
                    {/* <p className='task_item_price'>+{props.task.price_one_install_employer}₽</p> */}
                </div>
            </div>
            {/* {!props.isUpmob ?  */}
                <div className="task_item_money_c flex">+{props.task.price_one_install_employer * getSmoozieMultiplier()}<span>{getMoneyType(props.isUpmob)}</span></div>
            {/* :
                <p className="task_item_money_c">+{props.task.price_one_install_employer}₽</p>
            } */}
        </div>
    );
};

export default TaskItem;