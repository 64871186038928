/* eslint-disable eqeqeq */
import React from 'react';
import { AppsType, getMoneyType, getSmoozieMultiplier } from '../custom_views/insta_custom_views/utils_android/UtilsAndroid';

const ActiveTaskItem = ({ task, onTaskClicked, backView, isUpmob, isActive }) => {
    const openTask = () => {
        onTaskClicked(task, 'task', backView);
    };

    return (
        <div>
            {task.dateTitle && <p style={{ marginLeft: '16px' }} className="task_name">{task.dateTitle}</p>}
            <div className="task_item_container hover" onClick={openTask}>
                <div className="flex task_item_full_width">
                    <img className="task_item_icon custom_icons_task_shadow" src={task.icon} alt="" />
                    <div className="block justify_content task_item_full_width">
                        <p className="task_item_title task_name_overflow">{task.name}</p>
                        {!isActive && <p style={{color : '#2DB789'}} className="task_item_type">Выполнено</p>}
                        {/* <p className='task_item_price'>+{task.price_one_install_employer}₽</p> */}
                    </div>
                </div>
                {/* <p className="task_item_money_c">+{task.price_one_install_employer}₽</p> */}
                {/* {!isUpmob ?  */}
                    <div className="task_item_money_c flex">+{task.price_one_install_employer * getSmoozieMultiplier()}<span>{getMoneyType(isUpmob)}</span></div>
                {/* :
                    <p className="task_item_money_c">+{task.price_one_install_employer}₽</p>
                } */}
            </div>
       
        </div>
    );
};

export default ActiveTaskItem;