import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrow from './icons_android/arrow_left_android.svg';
import x_close_android from './icons_android/x_close_android.svg';
import { finish } from '../pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getIsAndroid, getIsDesktop, getMoneyType, getSmoozieMultiplier, isShowBackBtn, Pages } from '../pages_android/custom_views/insta_custom_views/utils_android/UtilsAndroid';
import more from '../icons/menu/more.svg'
import profile from '../icons/menu/profile.svg'
function NavbarAndroid({ isUpmob, count_money_r, toogleMenu, onBack, isClose, getMyInfo }) {
    const navigate = useNavigate();

    const sideButtonClicked = () => {
        if (window.location.pathname !== '/') {
            navigate(-1);
            getMyInfo()
        } else {
            toogleMenu();
        }
    };

    const closeUpmob = () => {
        finish();
    };
    const isDesktop = getIsDesktop()
    // const showBackButton = window.location.pathname !== '/';

    return (
        <header 
            style={isUpmob ? {} : {background: ""}} 
            className="header justify_content not_selectable"
        >
            <div style={{width : '100%'}} className="flex just_cont">
                {/* <img 
                    onClick={sideButtonClicked} 
                    src={showBackButton ? arrow : burger} 
                    className={showBackButton ? 'header__mobile-menu_back' : 'header__mobile-menu_burger'}
                    alt=""
                /> */}
                <div className='flex'>
                    {!isShowBackBtn() && <img 
                        onClick={sideButtonClicked} 
                        src={arrow} 
                        className={'header__mobile-menu_back hover'}
                        alt="back"
                    />}
                
                    {(!isDesktop && getIsAndroid()) ? <Link to={Pages.tasks}><p className="header_title hover">{isUpmob ? "Бонус" : "Smoozi"}</p></Link>
                    :
                    <Link to={Pages.tasks} style={{marginLeft : '0'}} className="navbar_item_center hover"><div style={{marginLeft : '0'}} className='navbar_item_text'>Все задания</div></Link>}
                </div>
                <div className='flex'>
                    
                    {isDesktop && <Link to={Pages.profile} className="navbar_item_center hover">
                        <div className='flex'>
                            <img alt='' className='' src={profile}/>
                            <div className='navbar_item_text'>Профиль</div>
                        </div>
                    </Link>}
                   
                    <Link to={Pages.payouts} style={isUpmob ? {} : {display : 'none'}} className="header_money hover">
                        {Math.floor(count_money_r) * getSmoozieMultiplier()}{getMoneyType(isUpmob)}
                    </Link>
                </div>
            </div>
            {!isUpmob && <img 
                onClick={closeUpmob} 
                className={(!isUpmob || isClose) ? "close_nav" : "not_display"} 
                src={x_close_android} 
                alt=""
            />}
        </header>
    );
}

export default NavbarAndroid;