/* eslint-disable eqeqeq */
import React from 'react';
import { getMoneyType, getSmoozieMultiplier } from '../custom_views/insta_custom_views/utils_android/UtilsAndroid';

const PayoutItem = ({ payout, isUpmob }) => {
    return (
        <div className="payout_item">
            <div className="task_name">{payout.time}</div>
            <div className="flex">
                <div className="task_name right_32">{(payout.amount * getSmoozieMultiplier()) + getMoneyType(isUpmob) + " (" + parseInt(payout.amount)}₽{")"}</div>
                <div className={payout.status_type == 0 || payout.status_type == 2 ? 'payout_green' : 'payout_red'}>
                    {payout.status_text}
                </div>
            </div>
        </div>
    );
};

export default PayoutItem;
